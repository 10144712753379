import React from "react";
// nodejs library to set properties for components
import PropTypes from "prop-types";
// nodejs library that concatenates classes
import classNames from "classnames";
import makeStyles from '@mui/styles/makeStyles';
import Icon from "@mui/material/Icon";

import styles from "/styles/jss/nextjs-material-kit-pro/components/infoStyle.js";

const useStyles = makeStyles(styles);

export default function InfoArea({
  title,
  description,
  iconColor = "gray",
  vertical,
  className,
  icon
}) {
  const classes = useStyles();
  const iconWrapper = classNames({
    [classes.iconWrapper]: true,
    [classes[iconColor]]: true,
    [classes.iconWrapperVertical]: vertical
  });
  const iconClasses = classNames({
    [classes.icon]: true,
    [classes.iconVertical]: vertical
  });
  const infoAreaClasses = classNames({
    [classes.infoArea]: true,
    [className]: className !== undefined
  });

  let iconElement = null;
  switch (typeof icon) {
    case "string":
      iconElement = <Icon className={iconClasses}>{icon}</Icon>;
      break;
    default:
      iconElement = <icon className={iconClasses} />;
      break;
  }

  return (
    <div className={infoAreaClasses}>
      <div className={iconWrapper}>{iconElement}</div>
      <div className={classes.descriptionWrapper}>
        <h4 className={classes.title}>{title}</h4>
        <div className={classes.description}>{description}</div>
      </div>
    </div>
  );
}

InfoArea.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.node.isRequired,
  iconColor: PropTypes.oneOf([
    "primary",
    "warning",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  vertical: PropTypes.bool,
  className: PropTypes.string
};
