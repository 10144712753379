import React from "react";
import Image from "next/legacy/image";
import makeStyles from "@mui/styles/makeStyles";
// @mui/icons-material
// import  from "@mui/icons-material/";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";
import Card from "components/Card/Card.js";
import CardAvatar from "components/Card/CardAvatar.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Button from "components/CustomButtons/Button.js";
import Muted from "components/Typography/Muted.js";

import teamsStyle from "styles/jss/nextjs-material-kit-pro/pages/sectionsSections/teamsStyle.js";

const useStyles = makeStyles(teamsStyle);

export default function SectionTeams({ ...rest }) {
  const classes = useStyles();
  return (
    <div className="cd-section" {...rest}>
      {/* Team 5 START */}
      <div className={classes.team + " " + classes.section}>
        <Image
          layout="fill"
          objectFit="cover"
          src={"/img/home_image-sm.webp"}
          alt=".."
        />

        <div className={classes.container}>
          <GridContainer>
            <GridItem
              xs={12}
              sm={8}
              md={8}
              className={
                classes.mlAuto + " " + classes.mrAuto + " " + classes.textCenter
              }
            >
              <h2 className={classes.title}>Meet Our Team</h2>
            </GridItem>
          </GridContainer>
          <GridContainer>
            <GridItem xs={12} sm={6} md={6}>
              <Card profile plain className={classes.card5}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                      <a href="/about/profile-scott-sunderland">
                        <Image
                          layout="responsive"
                          width={207}
                          height={215}
                          src="/img/faces/scott-profile-small.webp"
                          alt="..."
                        />
                      </a>
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <CardBody plain>
                      <h4 className={classes.cardTitle}>Scott Sunderland</h4>
                      <Muted>
                        <h6 className={classes.cardCategory}>
                          FOUNDER/LEAD CONSULTANT
                        </h6>
                      </Muted>
                      <p className={classes.description}>
                        We{"'"}re in a pandemic... a pandemic of busy-ness! I
                        think people appreciate quality work more than ever!
                      </p>
                    </CardBody>
                    <CardFooter profile plain>
                      <a
                        href=" https://twitter.com/ScottSunderl4nd"
                        target="_blank"
                      >
                        <Button justIcon simple>
                          <i className="fab fa-twitter" />
                        </Button>
                      </a>
                      <a
                        href="https://www.linkedin.com/in/scott-sunderland-86780641/"
                        target="_blank"
                      >
                        <Button justIcon simple>
                          <i className="fab fa-linkedin" />
                        </Button>
                      </a>
                      <a href="https://github.com/guruscott" target="_blank">
                        <Button justIcon simple>
                          <i className="fab fa-github" />
                        </Button>
                      </a>
                    </CardFooter>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card profile plain className={classes.card5}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                      <a href="/about/profile-victoria-prodromidis">
                        <Image
                          layout="responsive"
                          width={207}
                          height={215}
                          src="/img/faces/Victoria-sq.webp"
                          alt="..."
                        />
                      </a>
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <CardBody plain>
                      <h4 className={classes.cardTitle}>
                        Victoria Prodromidis
                      </h4>
                      <Muted>
                        <h6 className={classes.cardCategory}>
                          Marketing Executive
                        </h6>
                      </Muted>
                      <p className={classes.description}>
                        Marketing is no longer about the stuff that you make,
                        but the stories you tell.
                      </p>
                    </CardBody>
                    <CardFooter profile plain>
                      <a
                        href="https://www.linkedin.com/in/victoria-prodromidis-109928126/"
                        target="_blank"
                      >
                        <Button justIcon simple>
                          <i className="fab fa-linkedin" />
                        </Button>
                      </a>
                    </CardFooter>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card profile plain className={classes.card5}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                      <a href="/about/profile-robert-dzudzar">
                        <Image
                          layout="responsive"
                          width={207}
                          height={215}
                          src="/img/faces/Robert-sq.webp"
                          alt="..."
                        />
                      </a>
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <CardBody plain>
                      <h4 className={classes.cardTitle}>Robert Dzudzar</h4>
                      <Muted>
                        <h6 className={classes.cardCategory}>
                          Data Science Consultant
                        </h6>
                      </Muted>
                      <p className={classes.description}>
                        Data is like the Universe, vast and powerful.
                      </p>
                    </CardBody>
                    <CardFooter profile plain>
                      <a
                        href="https://au.linkedin.com/in/robertdzudzar"
                        target="_blank"
                      >
                        <Button justIcon simple>
                          <i className="fab fa-linkedin" />
                        </Button>
                      </a>
                    </CardFooter>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
            <GridItem xs={12} sm={6} md={6}>
              <Card profile plain className={classes.card5}>
                <GridContainer>
                  <GridItem xs={12} sm={5} md={5}>
                    <CardHeader image plain>
                      <a href="">
                        <Image
                          layout="responsive"
                          width={207}
                          height={215}
                          src="/img/faces/Chirag-circle.jpeg"
                          alt="..."
                        />
                      </a>
                    </CardHeader>
                  </GridItem>
                  <GridItem xs={12} sm={7} md={7}>
                    <CardBody plain>
                      <h4 className={classes.cardTitle}>Chirag Diyora</h4>
                      <Muted>
                        <h6 className={classes.cardCategory}>Developer</h6>
                      </Muted>
                      <p className={classes.description}>
                        I engineer the future with lines of code and the power
                        of imagination.
                      </p>
                    </CardBody>
                    <CardFooter profile plain>
                      <a href="" target="_blank"></a>
                    </CardFooter>
                  </GridItem>
                </GridContainer>
              </Card>
            </GridItem>
          </GridContainer>
        </div>
      </div>
      {/* Team 5 END */}
    </div>
  );
}
